@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

:root {
  --old-page-light-blue: #5AAAFC;
  --old-page-dark-blue: #305BA8;

  --primary-color: var(--old-page-dark-blue);
  --secondary-color: var(--old-page-light-blue);
}

/* Navbar Title Color*/
#site-nav > .site-title {
  color: var(--primary-color);
}

/* Navbar Subtitle Color*/
#site-nav .site-subtitle {
  color: black;
}

/* Title Color for each Page */
h1,
#page-title, 
#main-title {
  color: var(--primary-color);
}

/* smaller Title Color for each page - ## in MD */
h2 {
  color: var(--secondary-color);
}

/* sizing utility classes */
.width-100 {
    width: 100px
}

.width-200 {
    width: 200px
}

.width-300 {
    width: 300px
}

.team-page-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-gap: 1rem;
  grid-template-rows: auto;
}
